<template>
	<div class="single-user">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$t("titles.user")}}</div>
			<div v-if="mayEdit" class="btn-toolbar mb-2 mb-md-0">
				<small-button :text="$t('terms.edit')" @click.native="goTo({name: 'user-edit',params:{id:routeEntity.id}})" />
			</div>
		</div>
		<spinner v-if="isFetchingRequiredApiData === true"/>
		<template v-else>
			<form-container>
				<div class="part-label col-12">{{$t("titles.userDetails")}}</div>
				<field-input :label='$t("labels.name")' v-model="routeEntity.name" :disabled="true" />
				<field-input :label='$t("labels.email")' v-model="routeEntity.email" :disabled="true"  />
				<field-input :label='$t("labels.phone")'  v-model="routeEntity.mobile" :disabled="true"  />
				<field-input v-if="$route.name === 'user-add'" :label='$t("labels.password")' placeholder='$t("labels.password")' v-model="routeEntity.password" type="password" :disabled="true"  />
				<field-select :label='$t("labels.status")'  v-model="routeEntity.state" :options="stateOptions" :disabled="true" />
				<field-select :label='$t("labels.locale")'  v-model="routeEntity.locale" :options="localeOptions" :disabled="true"  />
			</form-container>
			<form-container v-if="mayViewCompany">
				<div class="part-label col-12">{{$t("titles.company")}}</div>
				<entity-select :value="routeEntity.companies" :label='$t("labels.memberOfCompany")' entity="Company" search-property="name" display-property="name" value-property="@id" :disabled="true"  />
				<entity-select :value="userHasPermissionGroupOnCompany" :label='$t("labels.companyPermissionGroups")' entity="PermissionGroup" search-property="name" display-property="name" value-property="@id" :multiple="true" :disabled="true"  />
			</form-container>
			<form-container v-if="mayViewDistrict">
				<div class="part-label col-12">{{$t("titles.district")}}</div>
				<entity-select :value="routeEntity.districts" :label='$t("labels.district")' entity="District" search-property="name" display-property="name" value-property="@id" :disabled="true"  />
				<entity-select :value="userHasPermissionGroupOnDistrict" :label='$t("labels.districtPermissionGroups")' entity="PermissionGroup" search-property="name" display-property="name" value-property="@id" :multiple="true" :disabled="true"  />
			</form-container>
			<form-container v-if="mayViewActivity">
				<div class="part-label col-12">{{$t("titles.pilotHours")}}</div>
				<div class="col-6">
					<div class="row">
						<field-select v-model="activityYear" class="col-6" :label='$t("labels.year")' :options="yearOptions"  />
						<field-select v-model="activityMonth" class="col-6" :label='$t("labels.month")' :options="monthOptions" />
					</div>
				</div>
				<div class="col-6">
					<spinner v-if="isFetchingActivity" class="pt-3" />
					<template v-else>
						<strong>{{$t('labels.totalHours')}}</strong>
						<p>{{activity.totalReadable}}</p>
					</template>
				</div>
			</form-container>
		</template>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import isGranted from "@/mixin/isGranted";
	import {mapGetters, mapState} from "vuex";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import SelectInput from "@/components/inputs/SelectInput";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import SmallButton from "@/components/buttons/SmallButton";
	import {extractIdFromIri} from "@/helperFunctions";
	import requiresApiRequest from "@/mixin/requiresApiRequest";
	import permissions from "@/permissions";
	import FieldSelect from "@/components/inputs/FieldSelect";
	import permissionGroups from "@/permissionGroups";

	export default {
		name: "SingleUser",
		components: {FieldSelect, Spinner, FormContainer, FieldInput, SelectInput, SmallButton, EntitySelect},
		mixins: [isGranted,requiresApiRequest],
		data(){
			return {
				isLoading: true,
				isFetchingActivity: true,
				activityYear: 0,
				activityMonth: 0,
				lastActivityYear: false,
				lastActivityMonth: false,
				activity: {},
				stateOptions: [
					{
						value: 0,
						text: this.$t('terms.inactive')
					},
					{
						value: 1,
						text: this.$t('terms.active')
					}
				],
				localeOptions: [
					{
						value: 'da',
						text: this.$t('locales.da')
					},
					{
						value: 'en',
						text: this.$t('locales.en')
					}
				],
			}
		},
		computed: {
			...mapState('concrete', {
				users: 'users',
				permissionGroups: 'permissionGroups',
				userPermissionGroups: 'userPermissionGroups',
				viewingEntity: 'viewingEntity',
				apiKey: 'apiKey'
			}),
			...mapGetters('concrete', {
				hasGlobalSuperAdmin: 'hasGlobalSuperAdmin',
				viewingEntityIRIParts: 'viewingEntityIRIParts'
			}),
			yearOptions(){
				const d = new Date();
				const start = d.getFullYear();
				let options = [];
				for(let i = -2; i <= 2; i++)
				{
					options.push({
						value: start+i,
						text: start+i
					},)
				}
				return options;
			},
			monthOptions(){
				let options = [];
				for(let i = 0; i < 12; i++)
				{
					options.push({
						value: i+1,
						text: this.$t('monthsJs.'+(i+1))
					},)
				}
				return options;
			},
			viewingEntityType(){
				return typeof this.viewingEntityIRIParts.entity !== 'undefined' ? this.viewingEntityIRIParts.entity : false ;
			},
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.users[this.$route.params.id] !== 'undefined') ? this.users[this.$route.params.id] : false ;
			},
			userHasPermissionGroupOnCompany(){
				return (this.routeEntity !== false && this.routeEntity['userHasPermissionGroupOnCompany'].length !== 0) ? this.routeEntity['userHasPermissionGroupOnCompany'].map((value) => {return this.permissionGroups[extractIdFromIri(value['permissionGroup'])]['@id'];}) : [];
			},
			userHasPermissionGroupOnDistrict(){
				return (this.routeEntity !== false && this.routeEntity['userHasPermissionGroupOnDistrict'].length !== 0) ? this.routeEntity['userHasPermissionGroupOnDistrict'].map((value) => {return this.permissionGroups[extractIdFromIri(value['permissionGroup'])]['@id'];}) : [];
			},
			mayEdit(){
				if(this.hasGlobalSuperAdmin || this.isGranted(permissions.USER.UPDATE) )
					return true;
				
				return (this.viewingEntity !== false) ? this.isGranted(permissions.USER.UPDATE,this.viewingEntity) : false;
			},
			mayViewActivity(){
				if(this.routeEntity.primaryRole !== permissionGroups.PILOT.key && this.routeEntity.primaryRole !== permissionGroups.DISTRICT_MANAGER.key)
					return false;
				
				return this.viewingEntity !== false ? this.isGranted(permissions.USER.MANAGE.PILOT,this.viewingEntity) : this.isGranted(permissions.USER.MANAGE.PILOT) ;
			},
			mayViewCompany(){
				if(this.routeEntity === false)
					return false;
				if(this.routeEntity.districts.length > 0)
					return false;

				if(this.viewingEntityType === 'Company')
					return true;
				else if(this.viewingEntityType !== false)
					return false;

				return this.hasGlobalSuperAdmin
			},
			mayViewDistrict(){
				if(this.routeEntity === false)
					return false;
				if(this.routeEntity.companies.length > 0)
					return false;

				if(this.viewingEntityType === 'District')
				{
					return true;
				}
				else if(this.viewingEntityType === 'Company')
				{
					return this.isGranted(permissions.USER.PERMISSION.UPDATE,this.viewingEntity)
				}
				else if(this.viewingEntityType !== false)
					return false;

				return this.hasGlobalSuperAdmin
			},
		},
		methods: {
			goTo(route){
				if(typeof route.params.lang === 'undefined')
					route.params.lang = this.$i18n.locale
				this.$router.push(route);
			},
			fetchEntity(){
				let promises = [];

				promises.push(this.addRequiredApiRequest({type: 'static',stateProperty: 'permissionGroups',payload:{entity: {'@type':'PermissionGroup'},parameters:{prPage:'9999'}}}));

				if(this.routeEntity === false)
					promises.push(this.addRequiredApiRequest({type: 'generic',payload:{entity: {'id': this.$route.params.id,'@type':'User'}}}));

				if(promises.length > 0)
				{
					Promise.all(promises)
					.catch(error => {
						// @todo error handling - logout / reset?
						console.log(error);
					})
				}
			},
			buildActivityParameters(){
				let parameters = [];
				const d = new Date();
				const currentYear = d.getFullYear();
				const currentMonth = d.getMonth()+1;
				
				if(currentYear !== this.activityYear)
					parameters.push('year='+this.activityYear);
				
				if(currentMonth !== this.activityMonth)
					parameters.push('month='+this.activityMonth);
				
				if(this.viewingEntity !== false && this.viewingEntityType === 'District')
					parameters.push('district='+this.viewingEntityIRIParts.id);
				else if(typeof this.routeEntity.districts !== 'undefined' && this.routeEntity.districts.length > 0)
					parameters.push('district='+extractIdFromIri(this.routeEntity.districts[0]));
				
				return parameters.length > 0 ? '?'+parameters.join('&') : '';
			},
			fetchActivity(){
				if(this.routeEntity !== false && (this.lastActivityMonth !== this.activityMonth || this.lastActivityYear !== this.activityMonth))
				{
					this.isFetchingActivity = true;
					this.lastActivityMonth = this.activityMonth+0;
					this.lastActivityYear = this.activityMonth+0;
					this.$concreteApi.get(this.apiKey,'/users/'+this.$route.params.id+'/activity'+this.buildActivityParameters())
					.then(response => {
						console.log(response);
						this.isFetchingActivity = false;
						this.activity = response;
					})
					.catch(error => {
						console.log(error)
						this.isFetchingActivity = false;
					})
				}
			}
		},
		mounted()
		{
			const d = new Date();
			this.activityYear = d.getFullYear();
			this.activityMonth = d.getMonth()+1;
			this.fetchEntity();
			if(this.mayViewActivity)
				this.fetchActivity();
		},
		watch: {
			routeEntity(){
				this.fetchActivity();
			},
			mayViewActivity(to){
				if(to)
					this.fetchActivity()
			},
			activityYear(){
				this.fetchActivity();
			},
			activityMonth(){
				this.fetchActivity();
			},
			$route: {
				deep:true,
				handler: function(){
					this.fetchEntity();
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.part-label{
		font-size: 1rem;
		color: $lightGray;
		pointer-events: none;
	}
</style>
